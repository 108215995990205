#register.page .page-header {
    background-color: #e5f4ff;
    border-bottom: 1px solid #cceaff;
    box-shadow: 0px 15px 10px black;
    margin: 0;
    padding: 5px 15px;
}
#register.page .page-body {
    height: calc(100vh - 50px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #e5f4ff;
}
#register.page .page-body .page-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    padding: 30px;
    min-width: 415px;
}
#register.page .page-body .form-name {
    cursor: default;
    font-size: 24px;
    margin-bottom: 1em;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}
#register.page .page-body .form-name .form-name-logo {
    font-weight: 600;
}
#register.page .page-body .form-name .form-name-logo .form-name-logo-mn {
    color: #0099ff;
}
#register.page .page-header .page-name-delimiter {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
#register.page .page-header .page-name {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400;
  }

#register.page .page-body .btn-login {
    background-color: #0099ff;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: 100%;
}
#register.page a {
    text-decoration: none;
}
#register.page .page-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 50px;
    width: 100%;
}
#register.page .page-footer p.copyrights {
    font-size: 12px;
}