html { font-size: calc(0.55rem + 0.20vw); }
body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  color: #333333;
}

/*Custom Check box*/
.page .page-body .form-check {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 0;
}
.page .page-body .form-check .form-check-label {
  display: block;
  margin-left: 25px;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
.page .page-body .form-check .form-check-label input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-right: 15px;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:before,
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:after {
  position: absolute;
  top: 0;
  left: 0;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #007bff;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 2px;
  margin-top: 2px;
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]
  + .input-helper:after {
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  font-family: "FontAwesome";
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
  content: "\F00c";
  font-size: 12px;
  margin-top: 1px;
  margin-left: 2px;
  color: white;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:before {
  background: #007bff;
  border-width: 2px;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper:after {
  width: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]:disabled
  + .input-helper::before {
  border-color: #ebedf2;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="checkbox"]:disabled:checked
  + .input-helper::after {
  background: #ebedf2;
  color: white;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]
  + .input-helper:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: solid #007bff;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]
  + .input-helper:after {
  content: "";
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
  position: absolute;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:before {
  background: #007bff;
  border-width: 0;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]:checked
  + .input-helper:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]:disabled
  + .input-helper::before {
  border-color: #ebedf2;
}
.page
  .page-body
  .form-check
  .form-check-label
  input[type="radio"]:disabled:checked
  + .input-helper::before {
  background: #ebedf2;
}
.page .page-body .form-check .list-time {
  font-size: 12px;
  color: #6c7293;
  font-weight: 300;
  margin-left: 30px;
  text-decoration: none;
  display: block;
}
/*# sourceMappingURL=style.css.map */
.inputEnable {border: 1px solid #ffffff; font-weight: 700; font-size: 1.75rem;}
.inputEnable:hover, .inputEnable:focus {font-weight: normal; border: 1px solid #ced4da;}
.descEnable {border: 1px solid #ffffff; font-size: 1.25rem;}
.descEnable:hover, .descEnable:focus {border: 1px solid #ced4da;}