#reset.page .page-header {
  background-color: #e5f4ff;
  border-bottom: 1px solid #cceaff;
  box-shadow: 0px 15px 10px black;
  margin: 0;
  padding: 10px 15px; }
  #reset.page .page-header .logo {
    color: #333333 !important;
    font-size: 24px;
    font-weight: 600; }
    #reset.page .page-header .logo .logo-mn {
      color: #0099ff; }
  #reset.page .page-header .page-name-delimiter {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400;
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  #reset.page .page-header .page-name {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400; }
#reset.page .page-body {
  align-items: center;
  background: #e5f4ff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: calc(100vh - 57px);
  padding: 15px; }
  #reset.page .page-body .page-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    padding: 30px;
    min-width: 415px;
    max-width: 415px; }
    #reset.page .page-body .page-content .form-group input::placeholder {
      color: #6c757d !important;
      opacity: 0.5;
      font-size: 16px; }
    #reset.page .page-body .page-content .form-group input::-ms-input-placeholder {
      color: #6c757d !important;
      opacity: 0.5;
      font-size: 16px; }
    #reset.page .page-body .page-content .showPassword-margin {
      margin-bottom: 1rem; }
    #reset.page .page-body .page-content #PasswordProgress .progress {
      margin-top: 0.2rem; }
      #reset.page .page-body .page-content #PasswordProgress .progress .progress-bar {
        font-size: 10px;
        width: 100%; }
    #reset.page .page-body .page-content #newPassword .help-block .progress-bar {
      margin-top: 1rem; }
    #reset.page .page-body .page-content #newPassword #show_hide_password .input-group-append a {
      color: #495057; }
    #reset.page .page-body .page-content #newPassword #show_hide_password .input-group-append #basic-addon2 {
      background-color: transparent; }
    #reset.page .page-body .page-content #password-note {
      font-size: 12px; }
  #reset.page .page-body .form-name {
    cursor: default;
    font-size: 24px;
    margin-bottom: 1em;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center; }
    #reset.page .page-body .form-name .form-name-logo {
      font-weight: 600; }
      #reset.page .page-body .form-name .form-name-logo .form-name-logo-mn {
        color: #0099ff; }
  #reset.page .page-body .forgot-link {
    color: #333333 !important;
    font-size: 16px;
    text-decoration: none; }
    #reset.page .page-body .forgot-link:hover {
      color: #0099ff !important;
      text-decoration: underline !important; }
  #reset.page .page-body .btn-login {
    background-color: #0099ff;
    color: white;
    text-transform: uppercase; }
#reset.page .page-footer {
  bottom: 0px;
  height: 50px;
  left: 0px;
  position: fixed;
  width: 100%; }
  #reset.page .page-footer p.copyrights { font-size: 12px; }
  #reset.page .page-footer p.copyrights .mn-color { color: #0099ff; }
#reset.page .reset-success-alert {
  display: none;
  position: fixed !important;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 415px;
  height: 100px;
  margin: 0 auto; }
#reset.page .loginLink {text-decoration: none;}
  .validationError{
    color: crimson;
  }
.valid{
  color : #006600;
}
.notValid{
  color: #660000;
}
/*# sourceMappingURL=reset.css.map */
