#login.page .page-header {
  background-color: #e5f4ff;
  border-bottom: 1px solid #cceaff;
  box-shadow: 0px 15px 10px black;
  margin: 0;
  padding: 5px 15px; }
  #login.page .page-header .logo {
    color: #333333 !important;
    font-size: 24px;
    font-weight: 600; }
    #login.page .page-header .logo .logo-mn {
      color: #0099ff; }
  #login.page .page-header .page-name-delimiter {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400;
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  #login.page .page-header .page-name {
    color: #333333;
    cursor: default;
    font-size: 24px;
    font-weight: 400; }
#login.page .page-body {
  height: calc(100vh - 50px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #e5f4ff; }
  #login.page .page-body .page-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    padding: 30px;
    min-width: 415px; }
    #login.page .page-body .page-content .form-group input::placeholder {
      color: #6c757d !important;
      opacity: 0.5;
      font-size: 16px; }
    #login.page .page-body .page-content .form-group input::-ms-input-placeholder {
      color: #6c757d !important;
      opacity: 0.5;
      font-size: 16px; }
  #login.page .page-body .form-name {
    cursor: default;
    font-size: 24px;
    margin-bottom: 1em;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center; }
    #login.page .page-body .form-name .form-name-logo {
      font-weight: 600; }
      #login.page .page-body .form-name .form-name-logo .form-name-logo-mn {
        color: #0099ff; }
  #login.page .page-body .forgot-link {
    color: #333333 !important;
    font-size: 16px;
    text-decoration: none; }
    #login.page .page-body .forgot-link:hover {
      color: #0099ff !important;
      text-decoration: underline !important; }
  #login.page .page-body .btn-login {
    background-color: #0099ff;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: 100%; }
  #login.page a {
    text-decoration: none;
  }
#login.page .page-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 50px;
  width: 100%; }
  #login.page .page-footer p.copyrights {
    font-size: 12px; }
    #login.page .page-footer p.copyrights .mn-color {
      color: #0099ff; }
#login.page .login-success-alert {
  display: none;
  position: fixed !important;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 415px;
  height: 100px;
  margin: 0 auto; }
.login-error{
  color: #ff0000;
}
.btn-logout {
  background-color: #0099ff;
  color: white;
  text-transform: uppercase;
  display: flex;
  margin-left: auto;
  margin-top: 0px;
}

/*# sourceMappingURL=login.css.map */
