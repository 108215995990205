.footer {
  flex: 0 0 auto;
  color: #90969a;
  background-color: #1c2023;
  font-size: 1.3rem;
  line-height: 1.4;
}
.footer .footer-middle {
  padding: 6rem 0 0.6rem;
  letter-spacing: 0.005em;
}
.footer .footer-middle .container {
  position: relative;
}
.footer .footer-middle .footer-ribbon {
  position: absolute;
  padding: 1rem;
  left: 1.5rem;
  top: -7.7rem;
  color: #fff;
  font-size: 2.1rem;
  min-width: 13rem;
  line-height: 1;
  letter-spacing: 0.01em;
  background-color: #0099ff;
  text-align: center;
}
.footer .footer-middle .footer-ribbon:before {
  display: block;
  position: absolute;
  top: 0;
  left: -1.5rem;
  width: 0;
  height: 0;
  content: "";
  border-right: 15px solid #007acc;
  border-top: 17px solid transparent;
}
.footer .footer-middle .widget {
  margin-bottom: 3.9rem;
}
.footer .footer-middle .widget .widget-title {
  color: #fff;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.footer .footer-middle .widget .links {
  margin-bottom: 0;
}
.footer .footer-middle .widget .links li {
  position: relative;
  margin-bottom: 0.7rem;
}
.footer .footer-middle .widget .links li a {
  color: inherit;
}
.footer .footer-middle .widget .links li a:hover,
.footer .footer-middle .widget .links li a:focus {
  color: #fff;
  text-decoration: none;
}
.footer .footer-middle .widget .contact-info {
  margin-top: -0.08rem;
  margin-bottom: 0;
}
.footer .footer-middle .widget .contact-info li {
  position: relative;
  line-height: 1.4;
  margin-bottom: 0.9rem;
}
.footer .footer-middle .widget .contact-info li .contact-info-label {
  display: block;
  font-weight: 600;
  font-size: 1.3rem;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  letter-spacing: 0.02em;
}
.footer .footer-middle .widget-newsletter p {
  margin-top: -0.4rem;
  margin-bottom: 3.5rem;
  line-height: 1.67;
  max-width: 290px;
}
.footer .footer-middle .widget-newsletter form {
  position: relative;
  margin-bottom: 0;
  max-width: 370px;
}
.footer .footer-middle .widget-newsletter .form-control {
  height: 4.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  border: 1px solid #353a3e;
  background-color: transparent;
  padding: 0.95rem 13.5rem 0.95rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0;
}
.footer .footer-middle .widget-newsletter .form-control:focus {
  color: #fff;
  border-color: #353a3e;
  background-color: transparent;
}
.footer
  .footer-middle
  .widget-newsletter
  .form-control::-webkit-input-placeholder {
  color: #6b7074;
}
.footer .footer-middle .widget-newsletter .form-control:-ms-input-placeholder {
  color: #6b7074;
}
.footer .footer-middle .widget-newsletter .form-control::-ms-input-placeholder {
  color: #6b7074;
}
.footer .footer-middle .widget-newsletter .form-control::placeholder {
  color: #6b7074;
}
.footer .footer-middle .widget-newsletter .btn {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #fff;
  border-color: #353a3e;
  background-color: transparent;
  text-transform: uppercase;
  border-radius: 0;
  padding: 1.4rem 1.9rem;
  min-width: 114px;
  text-align: center;
}
.footer .footer-middle .widget-newsletter .btn:hover,
.footer .footer-middle .widget-newsletter .btn:focus {
  color: #fff;
  border-color: #0099ff;
  background-color: #0099ff;
}
.footer .footer-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 5.6rem;
  border-top: 1px solid #353a3e;
  flex-direction: row;
  align-items: flex-start;
}
.footer .footer-bottom .footer-copyright {
  color: #a8a8a8;
  font-size: 1.1rem;
  margin-right: auto;
  margin-bottom: 1.2rem;
  margin-left: 0;
}
.footer .footer-bottom .footer-copyright .logoColor {
  color: #0099ff !important;
}
.footer .footer-bottom .footer-payments {
  margin-left: auto;
  margin-bottom: 1.5rem;
}
.footer .social-icons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.footer .social-icons .social-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  background-color: #43494e;
  margin-bottom: 0;
  width: 3.4rem;
  height: 3.4rem;
  font-size: 1.3rem;
  text-decoration: none;
  opacity: 1;
}
.footer .social-icons .social-icon + .social-icon {
  margin-left: 0.4rem;
}
.footer .social-icons .social-icon:hover,
.footer .social-icons .social-icon:focus {
  background-color: #0099ff;
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.footer .social-icons .social-icon.social-facebook {
  background-color: #3b5a9a;
  color: #fff;
}
.footer .social-icons .social-icon.social-twitter {
  background-color: #1aa9e1;
  color: #fff;
}
.footer .social-icons .social-icon.social-linkedin {
  background-color: #0073b2;
  color: #fff;
}
.footer .social-icons .social-icon.social-gplus {
  background-color: #dd4b39;
  color: #fff;
}
.footer .social-icons .social-icon.social-mail {
  background-color: #dd4b39;
  color: #fff;
}
.footer a {
  color: inherit;
}

@media (min-width: 768px) {
  .footer .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .footer .col-md-5 {
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}
@media (min-width: 992px) {
  .footer-bottom {
    flex-direction: row;
    align-items: flex-start;
  }
  .footer-bottom .footer-copyright {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .footer-bottom .footer-payments {
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;
  }
  .footer-bottom .social-icons {
    margin-left: 5.4rem;
  }
  .footer-bottom .contact-info {
    margin-bottom: 0;
    margin-right: 0;
  }

  .footer .col-lg-4 {
    -ms-flex: 0 0 35.1%;
    flex: 0 0 35.1%;
    max-width: 35.1%;
  }

  .footer .col-lg-8 {
    -ms-flex: 0 0 64.9%;
    flex: 0 0 64.9%;
    max-width: 64.9%;
  }
}
@media (min-width: 1200px) {
  .footer-bottom .social-icons {
    margin-left: 9.2rem;
  }
}
#scroll-top {
  height: 40px;
  position: fixed;
  right: 15px;
  width: 40px;
  z-index: 9999;
  bottom: 0;
  background-color: #43494e;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1;
  padding: 11px 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 5px 5px 0 0;
  transition: all 0.3s;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}
#scroll-top:hover,
#scroll-top:focus {
  background-color: #3a4045;
}
#scroll-top.fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

p {
  margin-bottom: 1.5rem;
}

ul,
ol {
  margin: 0 0 2.25rem;
  padding: 0;
  list-style: none;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

hr {
  max-width: 1730px;
  margin: 5.5rem auto 5.2rem;
  border: 0;
  border-top: 1px solid #dfdfdf;
}

sub,
sup {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  background: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
}
.loaded > .loading-overlay {
  opacity: 0;
  visibility: hidden;
}

.bounce-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;
}
.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #ccc;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
  animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
}
.bounce-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.bounce-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/*# sourceMappingURL=footer.css.map */
