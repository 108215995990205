/* .App {
  text-align: center;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
textarea{resize: none;}
.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/*.bodyContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}*/
.bodyContainer {
  padding: 1rem;
}
.todoListUL {
  width: 100%;
}
.todoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
}
.errorMsg {
  color: #ee0000;
}
.constraints {
  display: flex;
  align-content: center;
  align-items: center;
}
.buttonAlign {width: 100%;}
.font-size {font-size: 1.15rem!important;}
.constraint {
  padding: 5px;
  width: 100%;
}
.todoList {
  list-style: none;
  width: 100%;
  border: 1px solid #dddddd;
  margin-bottom: 0.5rem;
  border-top-width: 1px !important;
}
.form-label {font-weight: 700;}
.todoColor {background-color: #eeeeee;}
.todoColor:only-child {background-color: rgba(159, 210, 250, 0.3);}
.constraint1,
.constraint2 {
  border: 1px solid #0099CC;
  color: #0099CC;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
}
.constraint3,
.constraint4 {
  border: 1px solid #007E33;
  color: #007E33;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
}
.constraint5,
.constraint6 {
  border: 1px solid #CC0000;
  color: #CC0000;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
}


.todoEditor {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
}
.todoEdit-title h3 {
  font-size: 1.25rem;
  margin-bottom: 0rem;
  font-weight: 800;
}
.editTodo .card {
  border: 1px solid #cccccc;
}
.editTodo .card .card-header {
  padding: 1.25rem 1rem;
}
.constraintInput {
  text-align: right;
  width: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mn-color {
  color: #0099FF;
  font-weight: 600;
}
.logo {
  font-size: 32px !important;
  color: #333333 !important;
}
.todo {
  width: 80%;
  text-align: left;
  cursor: pointer;
}
.todoTitle {font-size: 1.25rem;font-weight: bold;color: #0b73b9;}
.todoDesc {font-size: 1.15rem; margin-left: 15px; padding: 5px 0px;font-weight: normal;}
.todo-navbar {
  background-color: #ffffff;
  box-shadow: 0px 21px 21px -15px rgba(0, 0, 0, 0.1);
}
.todo-title h4 {
  margin-bottom: 0rem;
  font-size: 1.25rem;
}
.Constraints-information {
  font-size: 12px;
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  padding: 10px;
  text-align: left;
  margin-top: 15px;
}
.Constraints-information ul {
  margin-bottom: 0px;
  padding-left: 15px;
}
.Constraints-information ul li {
  list-style-type: disc;
}
select {
  appearance: auto !important;
  -moz-appearance: auto !important;
}
.form-check-input[type="radio"]{margin-top: 5px;}